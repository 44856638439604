import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../state/GlobalStateProvider";

const CardServiceLink = () => {
  const [topServices, setTopServices] = useState([]);
  const {
    services: { data, loading },
  } = useGlobalState();
  useEffect(() => {
    if (data) {
      const filteredData = data.slice(4);
      setTopServices(filteredData);
    }
  }, [loading, data]);
  return (
    <div className="lg:md:p-[40px] p-5 border">
      <div className="relative pb-4 mb-8">
        <h4 className="text-xl font-semibold">Services</h4>
        <span className="absolute bottom-0 left-0 w-[40px] h-[2px] bg-blue-600"></span>
      </div>
      <div>
        <ul>
          {topServices?.map((service) => (
            <li key={service.id} className="py-3 border-b border-dashed">
              <Link
                className="text-md text-slate-600 inline-flex items-center gap-2 transition hover:text-blue-400"
                to={`/services/${service?._id}`}
              >
                <span className="text-xs">
                  <BsChevronRight />
                </span>
                <span className="">{service?.title} </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CardServiceLink;
