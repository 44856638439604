import React, { useEffect, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

// componets
import CardSponsor from "../Cards/CardSponsor";
import { useGlobalState } from "../../state/GlobalStateProvider";

const SliderSponsors = () => {
  const { apiUrl } = useGlobalState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetch(`${apiUrl}api/v2/partners`)
      .then((res) => res.json())
      .then((data) => {
        setClients(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);
  let slidePerView = 4;
  if (window.screen.availWidth < 570) {
    slidePerView = 1;
  }
  if (loading) {
    return (
      <div>
        <p>Loading....</p>
      </div>
    );
  }
  return (
    <Swiper
      slidesPerView={slidePerView}
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      navigation={true}
      pagination={false}
      modules={[Navigation, Autoplay]}
      className="mySwiper brands-slider"
    >
      {clients?.map((client) => (
        <SwiperSlide key={client.id}>
          <CardSponsor logo={client.image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderSponsors;
