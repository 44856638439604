import React, { useEffect, useState } from "react";

// component
import SectionHeader from "../../components/SectionHeader";
import CardService from "../../components/Cards/CardService";
import LoaderCardSkeleton from "../../components/Loader/LoaderCardSkeleton";
import { useGlobalState } from "../../state/GlobalStateProvider";

const Services = () => {
  const {
    services: { data, loading, error },
  } = useGlobalState();
  const [tag, setTag] = useState("all");
  return (
    <section>
      <SectionHeader title="Services Tab" />
      <div
        className="lg:md:py-[120px] py-[60px] lg:md:px-0 px-4"
        // style={{
        //   backgroundImage: `url(${bgImage})`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <div className="max-w-[1200px] mx-auto">
          {loading ? (
            <>
              <div className="max-w-[1200px] mx-auto my-[50px]">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 justify-items-center">
                  <LoaderCardSkeleton />
                  <LoaderCardSkeleton />
                  <LoaderCardSkeleton />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mb-[50px] flex justify-center">
                <button
                  onClick={() => setTag("all")}
                  className={` px-6 py-4 font-medium  rounded-l-full shadow-xl transition-all ease-in-out duration-300 hover:bg-secondary hover:text-white ${
                    tag === "all"
                      ? "bg-secondary text-white"
                      : "bg-white text-slate-600"
                  }`}
                >
                  All
                </button>
                <button
                  onClick={() => setTag("residential")}
                  className={` px-6 py-4 font-medium  border-x-[1px] shadow-xl transition-all ease-in-out duration-300 hover:bg-secondary hover:text-white ${
                    tag === "residential"
                      ? "bg-secondary text-white"
                      : "bg-white text-slate-600"
                  }`}
                >
                  Residential
                </button>
                <button
                  onClick={() => setTag("commercial")}
                  className={` px-6 py-4 font-medium  rounded-r-full shadow-xl transition-all ease-in-out duration-300 hover:bg-secondary hover:text-white ${
                    tag === "commercial"
                      ? "bg-secondary text-white"
                      : "bg-white text-slate-600"
                  }`}
                >
                  Commercial
                </button>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
                {data?.map((service) => (
                  <CardService key={service.id} service={service} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Services;
