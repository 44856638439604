import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InputDatePicker from "../Inputs/InputDatePicker";
import { useGlobalState } from "../../state/GlobalStateProvider";

const FormBookService = ({ serviceId = "" }) => {
  const {
    apiUrl,
    services: { loading, data, error },
  } = useGlobalState();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const filteredItem = data.map((item) => ({
      label: item.title,
      value: item.id,
    }));
    setOptions(filteredItem);
  }, [loading]);
  const [startDate, setStartDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const service = options.find((item) => item.value === serviceId);
    data.serviceId = serviceId;
    data.serviceName = service.label;
    data.bookingDate = startDate.toLocaleString().split(", ")[0];
    try {
      const response = await fetch(apiUrl + "api/v2/orders", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      toast.success(result.message);
      reset();
    } catch (error) {
      toast.error(error.message);
      reset();
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="lg:md:p-[40px] p-5 border"
    >
      <div className="relative pb-4 mb-8">
        <h4 className="text-xl font-semibold">Please Fill Information !!</h4>
        <span className="absolute bottom-0 left-0 w-[40px] h-[2px] bg-blue-600"></span>
      </div>
      <div className="mb-2">
        <input
          {...register("fullName", { required: true, maxLength: 20 })}
          aria-invalid={errors.name ? "true" : "false"}
          className="w-full px-[20px] py-[15px] bg-slate-100 outline-blue-500 outline-1 rounded"
          placeholder="Name*"
          type="text"
        />
        {errors.fullName?.type === "required" && (
          <p role="alert" className="pt-2 text-red-500">
            Error !! Full name is required
          </p>
        )}
      </div>
      <div className="mb-2">
        <input
          {...register("address", {
            required: true,
          })}
          aria-invalid={errors.address ? "true" : "false"}
          className="w-full px-[20px] py-[15px] bg-slate-100 outline-blue-500 outline-1 rounded"
          placeholder="Address*"
          type="text"
        />
        {errors.address?.type === "required" && (
          <p role="alert" className="pt-2 text-red-500">
            Error !! Address is required
          </p>
        )}
      </div>
      <div className="mb-2">
        <input
          {...register("phoneNo", {
            required: true,
          })}
          aria-invalid={errors.phone ? "true" : "false"}
          className="w-full px-[20px] py-[15px] bg-slate-100 outline-blue-500 outline-1 rounded"
          placeholder="Phone*"
          type="text"
        />
        {errors.phoneNo?.type === "required" && (
          <p role="alert" className="pt-2 text-red-500">
            Error !! Phone number is required
          </p>
        )}
      </div>

      <div className="mb-4">
        <InputDatePicker startDate={startDate} setStartDate={setStartDate} />
      </div>

      <div className="flex">
        <input
          className="w-full rounded py-4 bg-amber-400 text-lg font-medium cursor-pointer"
          type="submit"
          value="Book Now"
        />
      </div>
    </form>
  );
};

export default FormBookService;
