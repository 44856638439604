import React, { useEffect, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaQuoteLeft, FaQuoteRight, FaStar } from "react-icons/fa";

// components
import CardReview from "../Cards/CardReview";
import SliderLoader from "../Loader/SliderLoader";
import CardSliderError from "../Cards/CardSliderError";
import { useGlobalState } from "../../state/GlobalStateProvider";

const SliderReviews = () => {
  const {
    reviews: { data, loading, error },
  } = useGlobalState();
  let slidePerView = 2;
  if (window.screen.availWidth < 570) {
    slidePerView = 1;
  }
  if (loading) {
    return (
      <div>
        <p>Loading</p>
      </div>
    );
  }
  return (
    <Swiper
      slidesPerView={slidePerView}
      spaceBetween={30}
      loop={true}
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Pagination, Autoplay]}
      className="mySwiper testimonials-slider"
    >
      {data
        ?.filter((review) => review.status === true)
        .map((review) => (
          <SwiperSlide key={review.id}>
            <CardReview review={review} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default SliderReviews;
