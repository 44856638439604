import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useGlobalState } from "../../state/GlobalStateProvider.js";

const CardService = ({ service }) => {
  const { apiUrl } = useGlobalState();
  const { id, thumbDescription, title, icon, thumbnail } = service;
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className="rounded-md shadow-xl text-center select-none bg-white "
    >
      <div className="relative">
        <div className="relative">
          <img
            className="rounded-t-md max-h-[200px] w-full object-cover"
            src={apiUrl + "public/uploads/images/" + thumbnail}
            alt=""
          />
          <div
            className={`absolute top-0 left-0 w-full h-full bg-[#000] ${
              hover
                ? "opacity-50 transition-all ease-linear duration-300"
                : "opacity-0 transition-all ease-linear duration-300"
            }`}
          ></div>
        </div>
        <div className="absolute bottom-0 left-0 w-full flex justify-center">
          <div className="bg-secondary p-4 rounded-full translate-y-[50%]">
            <img
              className="w-[48px] h-[48px]"
              src={apiUrl + "public/uploads/images/" + icon}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="px-[30px] pt-[15px] pb-[40px] mt-[30px]">
        <h2 className="text-primary text-2xl font-bold text-ellipsis">
          <Link to={`/services/${id}`}>{title}</Link>
        </h2>
        <p className="my-[28px] text-slate-600">{thumbDescription}</p>
        <Link
          to={`/services/${id}`}
          className="inline-flex items-center gap-[6px] transition-all ease-linear duration-300 hover:gap-2 text-secondary"
        >
          <span>
            <BsFillArrowRightCircleFill />
          </span>
          <span className="font-semibold text-[16px]">READ MORE</span>
        </Link>
      </div>
    </div>
  );
};

export default CardService;
