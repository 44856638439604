import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";

import CardService from "../Cards/CardService";
import SliderLoader from "../Loader/SliderLoader";
import CardSliderError from "../Cards/CardSliderError";
import { useGlobalState } from "../../state/GlobalStateProvider";

const SliderServices = () => {
  let slidePerView = 3;
  let isMobile = false;
  if (window.screen.availWidth < 570) {
    slidePerView = 1;
    isMobile = true;
  }
  const {
    services: { data, loading, error },
  } = useGlobalState();
  return (
    <Swiper
      slidesPerView={slidePerView}
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      navigation={isMobile ? false : true}
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination, Navigation, Autoplay]}
      className="mySwiper services-slider"
    >
      {data?.map((service) => (
        <SwiperSlide key={service.id}>
          <CardService service={service} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderServices;
